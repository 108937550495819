@keyframes fade_in {
    from {
        transform: translate(0, 100%);
        opacity: 1;
    }
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes nudge {
    0%, 100% { transform: translateX(0); opacity: 1; }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
    20%, 40%, 60%, 80% { transform: translateX(10px); }
}

.fade_in_element {
    /* opacity: 0;
    animation-name: fade_in;
    animation-duration: 1s;
    animation-fill-mode: forwards; */
}

.nudge_element {
    animation-name: nudge;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
}