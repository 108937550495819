
.fixed_butcoin_text_logo {
  position: fixed;
  top: 100px;
  width: 600px;
  left: calc(50% - 300px);
}

.butcoin_text_logo {
  position: relative;
  display: block;
  margin-left: calc(50% - 300px);
  width: 600px;
  opacity: 0;
}

@media (max-width: 640px) {
  .butcoin_text_logo {
    margin-left: 20px;
    width: calc(100% - 40px);
  }

  .fixed_butcoin_text_logo {
    left: 20px;
    width: calc(100% - 40px);
  }
}


.sw_progress_container {
    width: 100%;
    /* height: 140px; */
    background-color: black;
}

.sw_countdown_container {
  width: 100%;
  /* margin-left: 20px; */
  /* border-radius: 25px; */
  color: white;
  height: 80px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.white_line {
  margin-left: 20px;
  width: calc(100% - 40px);
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
}

.sw_countdown_type {
  display: inline-block;
  margin-left: 10px;
  width: calc(25% - 12.5px);
  text-align: center;
  font-size: 12px;
}

.sw_countdown_holder {
  display: inline-block;
  margin-left: 10px;
  width: calc(25% - 12.5px);
  text-align: center;
  /* background-color: red; */
  height: 50px;
  font-size: 50px;
  font-weight: bold;
}

.sw_price_indicator {
    width: 100%;
    height: 50px;
    background-color: white;
    text-align: center;
    line-height: 50px;
}

.sw_main_section {
    position: relative;
    width: 100%;
    padding-bottom: 20px;
    background-color: var(--medium_gray);
    box-shadow: inset var(--medium_shadow);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.sw_loading_text_basic {
  position: relative;
  text-align: center;
  width: 100%;
  color: white;
}

.sw_loading_text {
    position: relative;
    padding-top: 10px;
    margin-bottom: 10px;
    line-height: 30px;
    text-align: center;
    width: 100%;
    color: white;
}

.sw_loading_text_left {
  position: relative;
  margin-left: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  text-align: left;
  width: 100%;
  color: white;
}

.sw_loading_bar {
    position: relative;
    margin-left: 20px;
    width: calc(100% - 46px);
    height: 30px;
    border-radius: 50px;
    border: 3px solid rgba(255, 255, 255, 0.5);
}

@keyframes sw_gradient_animation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 200% 50%; /* Increase the background position to make the animation move further */
    }
  }

  @keyframes move_dashes {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 28px 0; /* This should be the width of one full repeat (dash + gap) */
    }
  }
  
.sw_loading_inner {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 30px;
    background: linear-gradient(90deg, #efa854, #d02f93, #445cac, #05e4ff, #445cac, #d02f93, #efa854); /* Adjusted the direction for better progression */
    background-size: 10000% 100%; /* Adjust background size for a smoother transition */
    animation: sw_gradient_animation 15s linear infinite; /* Use 'linear' to maintain a constant speed */
}
  
.sw_loading_inner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px; /* Match the radius of the loading bar */
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.5), /* Color of the dash */
    rgba(255, 255, 255, 0.5) 10px, /* Width of the dash */
    transparent 10px,
    transparent 20px /* Width of the gap */
  );
  background-size: 28px 28px;
  z-index: 1; /* Ensure it sits above the gradient background */
  animation: move_dashes 2s linear infinite;
}


.sw_tab_container {
  position: relative;
  width: 100%;
  height: 50px;
  border-top: 1px solid var(--medium_gray);
  /* border-bottom: 1px solid rgb(208, 208, 208); */
  background-color: white;
  z-index: 2;
}

.sw_tab {
  display: inline-block;
  width: 50%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
}

.sw_tab_selected {
  color: var(--medium_green);
  border-bottom: 3px solid var(--background_green);
}

.extra_seperator {
  width: 100%;
  height: 20px;
}

.extra_seperator_small {
  width: 100%;
  height: 10px;
}

.sw_swap_container {
  position: relative;
  margin-left: 10px;
  width: calc(100% - 26px);
  background-color: white;
  border: 3px solid white;
  text-align: center;
  /* border-radius: 10px;
  border: 3px solid white; */
  box-shadow: var(--tiny_shadow);
  padding-bottom: 10px;
  border-radius: 15px;
  /* border: 3px solid var(--medium_gray); */
}

.sw_swap_container_focused {
  border: 1px solid var(--background_blue);
  padding: 2px;
  padding-bottom: 12px;
}

.sw_pre_label {
  margin-top: 10px;
  margin-left: 10px;
  text-align: left;
  font-size: 12px;
}

.edit_icon_container {
  position: relative;
  display: inline-block;
  width: calc(50% - 20px);
}

.sw_amount_input_container {
  vertical-align: top;
  display: inline-block;
  margin-top: 10px;
  margin-left: 0px;
  width: calc(100% - 30px);
  height: 50px;
  background-color: white;
  text-align: center;
  border-radius: 10px;
  border: none;
  /* border: 1px solid var(--dark_gray); */
  box-shadow: var(--small_shadow);
  border-radius: 50px;
  -webkit-appearance: none;
  padding-right: 30px;
}

.sw_amount_input_container_no_edit {
  padding-right: 0px;
  width: 100%;
  pointer-events: none;
}

.sw_edit_icon {
  position: absolute;
  top: 25px;
  right: 10px;
  width: 20px;
  height: 20px;
  /* background-color: blue; */
}

.full_icon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.sw_currency_selector {
  position: relative;
  vertical-align: top;
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
  width: calc(50% - 20px);
  height: 50px;
  background-color: white;
  text-align: center;
  border-radius: 10px;
  box-shadow: var(--small_shadow);
  border-radius: 50px;
}

@media (max-width: 540px) {

  

  .edit_icon_container {
    /* display: block; */
    margin-left: 0px;
    width: calc(100% - 120px);
  }

  .edit_icon_container_big {
    width: calc(100% - 90px);
  }

  .sw_currency_selector {
    /* display: block; */
    margin-left: 10px;
    width: 80px;
  }

  .sw_currency_selector_small {
    width: 50px;
  }
}

.sw_buy_button {
  margin-top: 20px;
  margin-left: 10px;
  width: calc(100% - 26px);
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  background-color: var(--background_green);
  text-align: center;
  border: 3px solid white;
  box-shadow: var(--tiny_shadow);
}

.sw_currency_icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  /* background-color: blue; */
}

.sw_drop_down_icon {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 20px;
  height: 20px;
}

.sw_currency_label {
  position: absolute;
  top: 10px;
  left: 50px;
  width: calc(100% - 90px);
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sw_tos_box {
  position: relative;
  margin-top: 20px;
  vertical-align: top;
  display: inline-block;
  margin-left: 20px;
  width: 28px;
  height: 28px;
  /* border: 1px solid var(--medium_gray); */
  border-radius: 5px;
  background-color: white;
  border: 1px solid white;
  box-shadow: var(--small_shadow);
}

.sw_tos_box_ticked {
  vertical-align: middle;
  background-color: var(--background_blue);
}

.sw_tos_box_lean {
  vertical-align: middle;
  margin-left: 0px;
}

.sw_tos_tick_icon {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 20px;
  height: 20px;
}

.sw_tos_text {
  margin-top: 20px;
  vertical-align: top;
  display: inline-block;
  margin-left: 20px;
  width: calc(100% - 112px);
  font-size: 12px;
}

.sw_tos_text_lean {
  vertical-align: middle;
  width: calc(100% - 62px);
}

.big_input_left {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
  width: calc(100% - 170px);
  height: 50px;
  /* background-color: red; */
  font-size: 32px;
  border: none;
  -webkit-appearance: none;
  font-weight: bold;
}


.no_events {
  pointer-events: none;
}

input:focus {
  border: none;
  outline: none;
}

.small_currency_selector {
  position: absolute;
  right: 10px;
  top: calc(50% - 20px);
  width: 135px;
  height: 40px;
  /* background-color: blue; */
  border-radius: 50px;
  box-shadow: var(--small_shadow);
}

.small_currency_icon {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  /* background-color: blue; */
}

.small_blockchain_icon_sw {
  position: absolute;
  left: 22px;
  top: 22px;
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 50px;
  object-fit: cover;
}

.small_drop_down_icon {
  position: absolute;
  top: calc(50% - 7px);
  right: 10px;
  width: 14px;
  height: 14px;
}

.small_currency_label {
  position: absolute;
  top: 5px;
  left: 45px;
  width: calc(100% - 65px);
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  font-size: 15px;
  /* background-color: red; */
  /* font-size: 12px; */
}

.uni_img {
  margin-top: 20px;
  width: 200px;
  margin-left: calc(50% - 100px);
}

@media (max-width: 540px) {
  .uni_img {
    margin-top: 20px;
    width: 150px;
    margin-left: calc(50% - 75px);
  }
}

.payment_methods_img {
  margin-top: 20px;
  width: 400px;
  margin-left: calc(50% - 200px);
}

@media (max-width: 540px) {
  .payment_methods_img {
    margin-top: 20px;
    width: calc(100% - 40px);
    margin-left: 20px;
  }
}

.suggestion_container {
  width: 100%;
  height: 48px;
  /* background-color: red; */
  overflow: scroll;
}

.suggestion_inner {
  height: 40px;
}

.suggestion_item {
  position: relative;
  display: inline-block;
  margin-top: 3px;
  margin-left: 10px;
  width: 85px;
  height: 30px;
  border-radius: 50px;
  box-shadow: var(--tiny_shadow);
  background-color: white;
}

.suggestion_label {
  position: absolute;
  top: 5px;
  left: 30px;
  width: calc(100% - 40px);
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.suggestion_currency_icon {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  /* background-color: blue; */
}

/* 

@keyframes sw_gradient_animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

.sw_loading_inner {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 30px;
    background: linear-gradient(270deg, #05e4ff, #5f2e96, #d02f93, #efa854);
    background-size: 800% 800%;
    animation: sw_gradient_animation 5s ease infinite;
} */