

.faq_item {
    position: relative;
    margin-bottom: 20px;
    /* min-height: 50px; */
    width: 100%;
    border-radius: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: var(--tiny_shadow);
    background-color: white;
}

.faq_item:last-child {
    margin-bottom: 0px;
}

.faq_title {
    margin-left: 20px;
    width: calc(100% - 65px);
    text-align: left;
}

.faq_answer {
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    text-align: left;
    display: none;
}

.faq_answer_selected {
    display: block;
}

.faq_icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
}

.faq_icon_selected {
    transform: rotate(45deg);
}