
.header_outer_container {
    top: 0px;
    position: fixed;
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.header_background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    object-fit: cover;
}

.header_outer_container_scrolled {
    /* top: -15px;
    background-color: black;
    height: 85px;
    z-index: 10000000000000; */
    top: -15px;
    height: 85px;
    z-index: 10000000000000;
    backdrop-filter: blur(20px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    /* background-color: rgba(255, 255, 255, 0.1); */
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.25); */
    /* background-color: rgba(0, 0, 0, 0.2); */
}

.header_container {
    position: absolute;
    width: 1200px;
    top: 0px;
    left: calc(50% - 600px);
    height: 100px;
    /* background-color: red; */
}



.header_spacer {
    width: 100%;
    height: 100px;
}

.header_logo {
    position: absolute;
    top: 25px;
    left: 10px;
    width: 50px;
    height: 50px;
    /* background-color: blue; */
}

.header_language_selector {
    position: absolute;
    right: 10px;
    top: 25px;
    width: 120px;
    height: 50px;
    background-color: white;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
}

.header_flag_img {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.header_lanaguage_down {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
}

.header_language_lbl {
    position: absolute;
    left: 50px;
    top: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
}

.header_buy_button {
    position: absolute;
    right: 140px;
    top: 25px;
    width: 120px;
    height: 50px;
    background-color: var(--background_green);
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
    /* white-space: nowrap; */
    overflow: hidden;
    font-weight: bold;
}

.header_links_container {
    position: absolute;
    top: 25px;
    left: calc(50% - 225px);
    width: 450px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: white;
    border-radius: 25px;
    /* background-color: black; */
    /* background-color: black; */
    /* background-color: white;
    color: black; */
}

.header_mobile_menu {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    height: 50px;
    display: none;
}

.header_link {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 1200px) {
    .header_container {
        left: 0px;
        width: 100%;
    }
}

@media (max-width: 990px) {
    .header_links_container {
        display: none;
    }
}

@media (max-width: 780px) {

    .header_container {
        height: 70px;
    }

    .header_outer_container_scrolled {
        top: 0px;
        height: 70px;
        /* background-color: rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
    }

    .header_logo {
        top: 10px;
    }

    

    .header_language_selector {
        top: 10px;
        width: 80px;
    }

    .header_language_lbl {
        display: none;
    }

    .header_buy_button {
        top: 10px;
        right: 100px;
    }

    .header_mobile_menu {
        display: block;
    }
}