

.feature_container {
    position: relative;
    width: 100%;
    padding-bottom: 10px;
}

.feature_img {
    display: inline-block;
    margin-top: 20px;
    width: 120px;
    height: 120px;
    vertical-align: top;
    object-fit: contain;
}

.feature_text {
    display: inline-block;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 160px);
    vertical-align: top;
    text-align: left;
}


@media (max-width: 840px) {
    .feature_img {
        display: block;
        width: 200px;
        height: 200px;
        margin-left: calc(50% - 100px);
    }

    .feature_text {
        display: block;
        margin-left: 0px;
        width: 100%;
        text-align: center;
    }
}
