.live_sales_container {
    position: relative;
    padding: 20px;
    width: calc(100% - 40px);
    height: 350px;
    overflow: scroll;
}

.live_sales_item {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 25px;
    width: 100%;
    height: 90px;
    box-shadow: var(--tiny_shadow);
    background-color: white;
}

.live_sales_item:last-child {
    margin-bottom: 5px;
}

.live_sales_container_stretch {
    padding: 2px;
    width: calc(100% - 4px);
}

.live_sales_icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    object-fit: contain;
    /* background-color: blue; */
}

.live_sales_title {
    position: absolute;
    top: 20px;
    left: 90px;
    height: 25px;
    line-height: 25px;
}

.live_sales_desc {
    position: absolute;
    top: 45px;
    left: 90px;
    height: 25px;
    line-height: 25px;
    /* width: calc(100% - 150px);
    overflow: hidden; */
}

.live_sales_timestamp {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 25px;
    line-height: 25px;
    text-align: right;
}


.live_sales_date {
    position: absolute;
    top: 45px;
    right: 20px;
    height: 25px;
    line-height: 25px;
    text-align: right;
    font-size: 12px;
}
