:root {
    --tiny_shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    --small_shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    --medium_shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    --big_shadow: 0px 0px 20px rgba(0, 0, 0, 0.75);

    --medium_gray: rgb(244, 244, 244);
    --dark_gray: rgb(200, 200, 200);
    --medium_green: #4ddb99;

    --background_green: #9bf5cb;
    --background_pink: #f29df5;
    --background_blue: #6cd0ff;
    --background_cyan: #7affff;
    --background_purple: #d69df6;
    --background_orange: #ffd484;
    --background_red: #fd9d87;
    --background_light_yellow: rgb(255, 252, 218);
    --background_light_blue: rgb(222, 249, 255);
    --background_yellow: #ffe362;
}

.yellow_background {
    background-color: var(--background_yellow);
}

.green_background {
    background-color: var(--background_green);
}

.pink_background {
    background-color: var(--background_pink);
}

.cyan_background {
    background-color: var(--background_cyan);
}

.blue_background {
    background-color: var(--background_blue);
}

.purple_background {
    background-color: var(--background_purple);
}

.orange_background {
    background-color: var(--background_orange);
}

.red_background {
    background-color: var(--background_red);
}

.black_background {
    background-color: black;
}

.gray_text {
    color: var(--dark_gray);
}

.green_text {
    color: var(--medium_green);
}

.red_text {
    color: red;
}

.orange_text {
    color: orange;
}

.white_background {
    background-color: white;
}