
.standard_box_inner {
    margin-top: 3px;
    padding-bottom: 10px;
    width: 100%;
    background-color: var(--medium_gray);
    box-shadow: inset var(--small_shadow);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.standard_box_text {
    padding: 20px;
    width: calc(100% - 40px);
    text-align: center;
}


@keyframes rotate_gradient {
    0% {
      transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.colored_button {
    position: relative;
    margin-top: 10px;
    margin-left: calc(50% - 100px);
    width: 200px;
    height: 50px;
    text-align: center;
    border-radius: 50px;
    margin-bottom: 10px;
    overflow: hidden;
    border: 3px solid white;
    line-height: 50px;
    box-shadow: var(--tiny_shadow);
    font-weight: bold;
}

.standard_button_container {
    position: relative;
    margin-top: 10px;
    margin-left: calc(50% - 100px);
    width: 200px;
    height: 58px;
    text-align: center;
    border-radius: 54px;
    margin-bottom: 10px;
    overflow: hidden;
    background-color: black;
}

.standard_button_gradient {
    left: 0px;
    top: -74px;
    position: absolute;
    width: 200px;
    height: 200px;
    background: linear-gradient(90deg, #05e4ff, #445cac, #d02f93, #efa854);
}

.standard_button_inner {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: 50px;
    line-height: 50px;
    background-color: inherit;
    border-radius: 50px;
    color: white;
}

/* .standard_button_container:hover {
    background-color: rgba(0, 0, 0, 0);
} */

.standard_buy_button {
    margin-top: 10px;
    margin-left: calc(50% - 100px);
    width: 200px;
    height: 50px;
    background-color: blue;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
    margin-bottom: 10px;
}

.standard_table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    background-color: white;
    box-shadow: var(--tiny_shadow);
    border-radius: 25px;
    overflow: hidden;
}

.standard_table tr:first-child td {
    border-top: none; /* Remove top border for the first row */
}

.standard_table tr td:first-child {
    border-left: none; /* Remove left border for the first column */
}

.standard_table tr:last-child td {
    border-bottom: none; /* Remove bottom border for the last row */
}

.standard_table tr td:last-child {
    border-right: none; /* Remove right border for the last column */
}

.standard_table td, .standard_table th {
    padding: 10px;
    border: 1px solid var(--medium_gray); /* Apply borders to cells */
    word-wrap: break-word;
    white-space: wrap; /* Adjust as needed */
}

/* Optionally, ensure the first row (if it's a header) has a top border */
.standard_table th {
    border-top: 1px solid var(--medium_gray);
}

.standard_table th:first-child, .standard_table td:first-child {
    width: 200px;
}

@media (max-width: 640px) {
    .standard_table th:first-child, .standard_table td:first-child {
        width: auto;
    }
}



.mail_list_input {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 212px);
    height: 30px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    /* border: 1px solid black; */
    /* border-radius: 25px; */
    box-shadow: var(--tiny_shadow);
    border: 3px solid white;
    border-radius: 50px;
    -webkit-appearance: none;
}

.mail_list_input:focus {
    border: 3px solid white;
}

.mail_list_subscribe_button {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    width: 140px;
    height: 50px;
    line-height: 50px;
    box-shadow: var(--tiny_shadow);
    border: 3px solid white;
    border-radius: 50px;
    font-weight: bold;
    text-align: center;
}

@media (max-width: 640px) {
    .mail_list_input {
        display: block;
        width: calc(100% - 46px);
        text-align: center;
    }

    .mail_list_subscribe_button {
        display: block;
        margin-top: 10px;
        margin-left: 0px;
        width: calc(100% - 6px);
    }
}

.inline_connect_button {
    position: relative;
    display: inline-block;
    width: 220px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    text-align: center;
    box-shadow: var(--tiny_shadow);
    font-weight: bold;
    border: 3px solid white;
    background-color: white;
}

.inline_link_copy {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 200px;
    height: 30px;
    line-height: 50px;
    border-radius: 50px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    box-shadow: var(--tiny_shadow);
    border: 3px solid white;
    -webkit-appearance: none;
}

.copy_icon_container {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    margin-left: 13px;
    width: 50px;
    height: 50px;
    right: 10px;
    background-color: white;
    border-radius: 50px;
    box-shadow: var(--tiny_shadow);
    border: 3px solid white;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.copy_icon_inner {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.small_text {
    font-size: 12px;
}

@media (max-width: 550px) {
    .inline_connect_button {
        width: calc(100% - 6px);
    }

    .inline_link_copy {
        width: calc(100% - 115px);
    }
}

.inline_content {
    display: inline-block;
}

.extra_text_thing {
    margin-left: 20px;
    width: calc(100% - 20px);
    color: red;
}