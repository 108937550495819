body {
    background-color: black;
}

@keyframes fade_in_start {
    0% { opacity: 0; }
    100% { opacity: 0.5; }
 }
 

.fixed_background_img {
    position: fixed;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    object-fit: cover;
    /* animation-name: fade_in_start;
    animation-duration: 0.5s; */
}

@keyframes fade_in_out {
    0%, 100% { opacity: 0.5; }
    50% { opacity: 0; }
 }
 

.fixed_background_img_change {
    animation-name: fade_in_out;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
}


.small_seperator {
    width: 100%;
    height: 20px;
}

.large_seperator {
    width: 100%;
    height: 200px;
}

.med_seperator {
    width: 100%;
    height: 40px;
}

.full_background {
    position: relative;
    min-height: 100%;
    width: 100%;
    background-color: black;
}

.outer_flex {
    display: flex;
    align-items: center;
}

.full_width_extra_container {
    width: 600px;
    position: relative;
    margin-left: calc(50% - 300px);
    font-size: 24px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    opacity: 0;
}

.full_background_image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
}

.large_title_text {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 40px;
    color: white;
}

.container_outer {
    position: relative;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
}

.central_container_box {
    width: 600px;
    min-height: 100px;
    position: relative;
    margin-left: calc(50% - 300px);
    background-color: white;
    border-radius: 25px;
    overflow: hidden;
    border: 3px solid white;
    opacity: 1;
    box-shadow: var(--big_shadow);
}

.forced_center {
    width: 600px;
    margin-left: calc(50% - 300px);
}

@media (max-width: 640px) {
    .central_container_box {
        width: calc(100% - 26px);
        margin-left: 10px;
    }

    .full_width_extra_container {
        margin-left: 20px;
        width: calc(100% - 40px);
        font-size: 18px;
        margin-bottom: 0px;
    }
}

/* @media (max-width: 840px) {
    .forced_center {
        width: calc(100% - 46px);
        margin-left: 20px;
    }
} */

/* .forced_center {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
} */

.cc_top_title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    /* font-family: "Permanent Marker", cursive; */
}

.handwritten {
    font-family: "Permanent Marker", cursive;
}

.seperator_img {
    opacity: 0.5;
    display: block;
    width: 100%;
    min-height: 30px;
}


.main_container {
    margin-left: calc(50% - 600px);
    width: 1200px;
    background-color: blue;
}

.safe_inner {
    width: calc(100% - 40px);
    margin-left: 20px;
}

.standard_box {
    width: calc(100% - 80px);
    margin-left: 20px;
    border: 5px solid var(--dark_blue);
    box-shadow: 10px 10px 20px 5px var(--neon_blue);
}

@media (max-width: 1200px) {
    .main_container {
        margin-left: 0px;
        width: 100%;
    }
}

.standard_illustration {
    width: 100%;
}