* {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
}

html, body {
    height:100%;
    font-size: 18px;
}

input {
    font-size: 18px;
}

/* img {
    pointer-events: none;
} */

.pointer {
    cursor: pointer;
}

.br_sep {
    margin-top: 20px;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
