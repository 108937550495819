
.modal_background {
    position: fixed;
    background-color: rgba(0,0,0,0.75);
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 1000000000000000;
}

.modal_container {
    position: relative;
    background-color: white;
    width: 600px;
    padding: 20px;
    max-height: calc(100% - 80px);
    /* overflow: scroll; */
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 25px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    box-shadow: var(--big_shadow);
}

.small_blockchain_icon {
    position: absolute;
    left: 48px;
    top: 48px;
    width: 30px;
    height: 30px;
    border: 2px solid white;
    border-radius: 50px;
    object-fit: cover;
}

.modal_title {
    width: 100%;
    height: 50px;
    line-height: 35px;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 1px solid var(--medium_gray);
}


.currency_search_bar {
    width: calc(100% - 30px);
    height: 30px;
    background-color: rgb(233, 233, 233);
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 30px;
    border: none;
    border-radius: 25px;
}

.currency_list_container {
    margin-top: 20px;
    margin-left: -5px;
    width: calc(100% - 10px);
    height: 400px;
    overflow: scroll;
    padding: 10px;
    padding-top: 2px;
}

.currency_source_indicator {
    position: absolute;
    right: 20px;
    top: 45px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    text-align: right;
}

.copy_address_button {
    display: inline-block;
    margin-top: 20px;
    height: 50px;
    width: 250px;
    line-height: 50px;
    background-color: var(--background_green);
    text-align: center;
    border-radius: 10px;
    vertical-align: middle;
    box-shadow: var(--tiny_shadow);
    border: 3px solid white;
}

.qr_code_button {
    margin-left: 20px;
    position: relative;
    display: inline-block;
    margin-top: 20px;
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 10px;
    vertical-align: middle;
    box-shadow: var(--tiny_shadow);
    background-color: var(--medium_gray);
    border: 3px solid white;
}

.qr_code_icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
}

@media (max-width: 500px) {
    .copy_address_button {
        width: calc(100% - 6px);
    }
}

@media (max-width: 640px) {
    .modal_container {
        padding: 20px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        max-height: none;
    }

    .currency_list_container {
        height: calc(100% - 140px);
    }
}


.modal_email_input {
    display: inline-block;
    margin-top: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    height: 30px;
    width: calc(100% - 170px);
    border: 1px solid black;
    border-radius: 25px;
    vertical-align: top;
    
}

.notify_button {
    display: inline-block;
    margin-top: 20px;
    height: 50px;
    width: 100px;
    text-align: center;
    line-height: 50px;
    margin-left: 20px;
    /* background-color: var(--background_cyan); */
    border-radius: 25px;
    border: 1px solid black;
    vertical-align: top;
}



.modal_email_input {
    margin-top: 20px;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 212px);
    height: 30px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: var(--medium_shadow);
    border: 3px solid white;
    border-radius: 50px;
    -webkit-appearance: none;
}

.notify_button {
    margin-top: 20px;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    width: 140px;
    height: 50px;
    line-height: 50px;
    box-shadow: var(--medium_shadow);
    border: 3px solid white;
    border-radius: 50px;
    font-weight: bold;
    overflow: hidden;
}

.modal_loading_bar {
    position: relative;
    margin-left: 0px;
    width: calc(100% - 6px);
    height: 30px;
    border-radius: 50px;
    border: 3px solid rgba(55, 55, 55, 0.5);
}

.language_item {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 25px;
    width: 100%;
    height: 90px;
    box-shadow: var(--small_shadow);
    background-color: white;
}

.language_item:last-child {
    margin-bottom: 5px;
}


.language_item_title {
    position: absolute;
    top: 20px;
    left: 90px;
    height: 50px;
    line-height: 50px;
}

.language_item_right_icon {
    position: absolute;
    top: 35px;
    right: 20px;
    width: 20px;
    height: 20px;
}

.close_modal {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
}

.break_all {
    word-break: break-all;
}

.warning_text_container {
    position: relative;
    margin-top: 20px;
    padding: 10px;
    padding-left: 40px;
    width: calc(100% - 50px);
    background-color: var(--background_light_blue);
    font-size: 12px;
    border-radius: 10px;
}

.warning_text_icon {
    position: absolute;
    left: 10px;
    height: 20px;
    width: 20px;
    top: calc(50% - 10px);
}

.iframe_payment {
    width: 100%;
    height: 720px;
}

@media (max-width: 640px) {
    .iframe_payment {
        margin-left: -20px;
        width: calc(100% + 40px);
    }
}


.other_button {
    display: inline-block;
    margin-top: 20px;
    height: 50px;
    width: calc(50% - 16px);
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    vertical-align: middle;
    box-shadow: var(--tiny_shadow);
    border: 3px solid white;
}

.other_button_first {
    margin-right: 20px;
}

.wallet_connect_button {
    display: block;
    position: relative;
    margin-top: 20px;
    height: 50px;
    width: calc(100% - 6px);
    line-height: 50px;
    /* text-align: center; */
    border-radius: 25px;
    vertical-align: middle;
    box-shadow: var(--tiny_shadow);
    border: 3px solid white;
    background-color: var(--medium_gray);
    cursor: pointer;
}

.wallet_connect_icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.wallet_connect_lbl {
    position: absolute;
    top: 5px;
    left: 55px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
}

.wallet_connect_right {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
}

@media (max-width: 640px) {
    .other_button {
        display: block;
        width: calc(100% - 6px);
    }
    .other_button_first {
        margin-right: 0px;
    }
    .hide_small {
        display: none;
    }
}