
.footer_container {
    position: relative;
    width: 100%;
    height: 250px;
    /* background-color: black; */
    background: linear-gradient(to bottom, rgba(0,0,0,0), #000000);
    /* overflow: hidden; */
}

.footer_bottom {
    position: absolute;
    top: 100%;
    height: 500px;
    width: 100%;
    background-color: black;
}

.footer_email_container {
    padding-top: 20px;
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: white;
    vertical-align: middle;
}

.footer_email_icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-color: blue;
    vertical-align: middle;
}

.footer_items_row {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: white;
}

.footer_item {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}


@media (max-width: 600px) {

    .footer_container {
        height: 600px;
    }

    .footer_item {
        display: block;
    }
}
